import Link from 'next/link';
import Image from 'next/image';
import { Player } from '@lottiefiles/react-lottie-player';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styles from './works-card.module.css';
import Htag, { HtagVariant } from './h-tag';
import HoverLine from './hover-line';
import { rgbDataURL } from '../utils/rgbDataUrl';

export interface ITag {
  id: number;
  link: string | null;
  name: string;
}

export interface WorksCardProps {
  src: string;
  lottie: string | null;
  title: string;
  link: string | null;
  description: string;
  tags: ITag[];
}

export default function WorksCard({
  src,
  lottie,
  title,
  link,
  description,
  tags,
}: WorksCardProps) {
  const [showLottie, setShowLottie] = useState(false);

  const isTab = useMediaQuery({
    query: '(min-width: 768px)',
  });

  useEffect(() => {
    setShowLottie(isTab);
  }, [isTab]);

  return (
    <li className={styles.card}>
      <div className={styles.images}>
        {link ? (
          <Link href={link} passHref prefetch={false}>
            <a>
              <Image
                width={590}
                height={400}
                priority
                placeholder="blur"
                blurDataURL={rgbDataURL()}
                objectFit="cover"
                src={src}
                alt={title}
              />
              {lottie && showLottie && (
                <Player
                  autoplay
                  loop
                  src={lottie}
                  background="transparent"
                  speed={1}
                  style={{ height: '100%', width: '100%' }}
                />
              )}
            </a>
          </Link>
        ) : (
          <>
            <Image
              width={590}
              height={400}
              priority
              placeholder="blur"
              blurDataURL={rgbDataURL()}
              objectFit="cover"
              src={src}
              alt={title}
            />
            {lottie && showLottie && (
              <Player
                autoplay
                loop
                src={lottie}
                background="transparent"
                speed={1}
                style={{ height: '100%', width: '100%' }}
              />
            )}
          </>
        )}
      </div>
      <div className={styles.content}>
        {link ? (
          <Link href={link} passHref prefetch={false}>
            <a>
              <Htag tag="h3" variant={HtagVariant.h5} className={styles.title}>
                {title}
              </Htag>
            </a>
          </Link>
        ) : (
          <Htag tag="h3" variant={HtagVariant.h5} className={styles.title}>
            {title}
          </Htag>
        )}
        <div className={styles.text}>
          <p>{description}</p>
        </div>
        <ul className={styles.list}>
          {tags.map((tag, index) => (
            <li key={tag.id} className={styles.item}>
              {tag.link ? (
                <HoverLine>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className={styles.tag}
                    href={tag.link}
                  >
                    {tag.name}
                    {index !== tags.length - 1 && ','}
                  </a>
                </HoverLine>
              ) : (
                <span className={styles.tag}>
                  {tag.name}
                  {index !== tags.length - 1 && ','}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
}
