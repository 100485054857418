const keyStr =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

const triplet = (e1: number, e2: number, e3: number) =>
  keyStr.charAt(e1 >> 2) +
  keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
  keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
  keyStr.charAt(e3 & 63);

export const rgbDataURL = (
  r = (Math.random() * 200).toFixed(0),
  g = (Math.random() * 200).toFixed(0),
  b = (Math.random() * 200).toFixed(0),
) => {
  return `data:image/gif;base64,R0lGODlhAQABAPAA${
    triplet(0, Number(r), Number(g)) + triplet(Number(b), 255, 255)
  }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;
};
