import Link from 'next/link';
import React from 'react';
import cn from 'classnames';
import styles from './footer.module.css';
import Logo, { type } from './logo';
import { Container, HoverLine } from '.';

interface FooterProps {
  variant?: 'main' | 'letslink' | 'fitkonnekt' | 'keto' | 'healthCase';
  logo?: type;
  line?: 'letslink' | 'white' | 'keto' | undefined;
}

export default function Footer({
  variant = 'main',
  logo = 'text',
  line,
}: FooterProps) {
  return (
    <footer className={cn(styles.footer, styles[variant])}>
      <Container>
        <div className={styles.footer__start}>
          <Logo className={styles.logo} appearance={logo} />
          <nav className={styles.footer__nav}>
            <ul className={styles.menu__list}>
              <li className={styles['menu__list-item']}>
                <HoverLine appearance={line}>
                  <Link href="/experience" passHref prefetch={false}>
                    <a className={styles['menu__list-link']}> Works </a>
                  </Link>
                </HoverLine>
              </li>

              <li className={styles['menu__list-item']}>
                <HoverLine appearance={line}>
                  <Link href="/#services" passHref prefetch={false}>
                    <a className={styles['menu__list-link']}>Services</a>
                  </Link>
                </HoverLine>
              </li>
              <li className={styles['menu__list-item']}>
                <HoverLine appearance={line}>
                  <Link href="/team" passHref prefetch={false}>
                    <a className={styles['menu__list-link']}>Team</a>
                  </Link>
                </HoverLine>
              </li>
            </ul>
          </nav>
        </div>
        <div className={styles.footer__middle}>
          <HoverLine appearance={line}>
            <a
              className={styles.footer__email}
              href="mailto:ruslan@mavinx.com "
              rel="noopener noreferrer"
            >
              ruslan@mavinx.com
            </a>
          </HoverLine>
          <HoverLine appearance={line}>
            <a
              className={styles.footer__phone}
              href="tel:+48662963438"
              rel="noopener noreferrer"
            >
              +48662963438
            </a>
          </HoverLine>
        </div>
        <div className={styles.footer__end}>
          <div className={styles.footer__rights}>
            (c) 2021. websoftware.biz. All rights reserved
          </div>
          <div className={styles.links}>
            <a
              className={styles.linkMap}
              href="https://goo.gl/maps/mqsg2a8ypRc7Qkjn8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                width="18"
                height="24"
                viewBox="0 0 18 24"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9 24C10.9839 24 18 13.5076 18 8.70175C18 3.89591 13.9706 0 9 0C4.02944 0 0 3.89591 0 8.70175C0 13.5076 7.1129 24 9 24ZM8.95161 13.193C11.4903 13.193 13.5484 11.2031 13.5484 8.74854C13.5484 6.29394 11.4903 4.30409 8.95161 4.30409C6.41288 4.30409 4.35484 6.29394 4.35484 8.74854C4.35484 11.2031 6.41288 13.193 8.95161 13.193Z"
                />
              </svg>
              Wroclaw, Poland
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
}
