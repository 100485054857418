import { DetailedHTMLProps, HTMLAttributes } from 'react';
import Link from 'next/link';
// import LogoIcon from '../public/logo/logo.svg';
// import WhiteLogoIcon from '../public/logo/white-logo.svg';
// import TextLogoIcon from '../public/logo/text-logo.svg';
// import LetslinkIcon from '../public/logo/letslink-logo.svg';
// import LogoBlack from '../public/logo/logo-black.svg';
// import LogoKeto from '../public/logo/logo-keto.svg';
// import HealthCase from '../public/logo/logo-healthCase.svg';
// import NFT from '../public/logo/nft.svg';

export type type =
  | 'primary'
  | 'white'
  | 'text'
  | 'letslink'
  | 'fitkonnekt'
  | 'keto'
  | 'healthCase'
  | 'nft';

export interface LogoProps
  extends DetailedHTMLProps<HTMLAttributes<SVGElement>, SVGElement> {
  appearance?: type;
}

export default function Logo({ appearance = 'primary', className }: LogoProps) {
  switch (appearance) {
    case 'primary':
      return (
        <div className={className}>
          <Link href="/" prefetch={false}>
            <a aria-label="websoftware.biz">
              websoftware.biz
              {/* <LogoIcon {...props} /> */}
            </a>
          </Link>
        </div>
      );

    case 'healthCase':
      return (
        <div className={className}>
          <Link href="/" prefetch={false}>
            <a aria-label="websoftware.biz">
              websoftware.biz
              {/* <HealthCase {...props} /> */}
            </a>
          </Link>
        </div>
      );

    case 'fitkonnekt':
      return (
        <div className={className}>
          <Link href="/" prefetch={false}>
            <a aria-label="websoftware.biz">
              websoftware.biz
              {/* <LogoBlack {...props} /> */}
            </a>
          </Link>
        </div>
      );

    case 'white':
      return (
        <div className={className}>
          <Link href="/" prefetch={false}>
            <a aria-label="websoftware.biz">
              websoftware.biz
              {/* <WhiteLogoIcon {...props} /> */}
            </a>
          </Link>
        </div>
      );

    case 'text':
      return (
        <div className={className}>
          <Link href="/" prefetch={false}>
            <a aria-label="websoftware.biz">
              websoftware.biz
              {/* <TextLogoIcon {...props} /> */}
            </a>
          </Link>
        </div>
      );
    case 'letslink':
      return (
        <div className={className}>
          <Link href="/" prefetch={false}>
            <a aria-label="websoftware.biz">
              websoftware.biz
              {/* <LetslinkIcon {...props} /> */}
            </a>
          </Link>
        </div>
      );
    case 'keto':
      return (
        <div className={className}>
          <Link href="/" prefetch={false}>
            <a aria-label="websoftware.biz">
              websoftware.biz
              {/* <LogoKeto {...props} /> */}
            </a>
          </Link>
        </div>
      );

    case 'nft':
      return (
        <div className={className}>
          <Link href="/" prefetch={false}>
            <a aria-label="websoftware.biz">
              websoftware.biz
              {/* <NFT {...props} /> */}
            </a>
          </Link>
        </div>
      );

    default:
      return (
        <div className={className}>
          <Link href="/" prefetch={false}>
            <a aria-label="websoftware.biz">
              websoftware.biz
              {/* <LogoIcon {...props} /> */}
            </a>
          </Link>
        </div>
      );
  }
}
