import { HTMLAttributes, DetailedHTMLProps } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import styles from './navigation.module.css';
import Arrow from '../public/arrow.svg';
import { HoverLine } from '.';

interface MenuItem {
  id: number;
  label: string;
  href: string;
}

export interface Menu {
  id: number;
  label: string;
  href?: string;
  drop?: MenuItem[];
}

export type appearanceType =
  | 'white'
  | 'letslink'
  | 'fitkonnekt'
  | 'keto'
  | 'healthCase'
  | 'nft';

export interface NavigationProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  menu: Menu[];
  appearance?: appearanceType;
  onHandleClick: () => void;
}

export default function Navigation({
  menu,
  className,
  appearance,
  onHandleClick,
  ...props
}: NavigationProps) {
  return (
    <nav
      className={cn(className, styles.nav, {
        [styles.nav__white]: appearance === 'white',
        [styles.nav__letslink]: appearance === 'letslink',
        [styles.nav__fitkonnekt]: appearance === 'fitkonnekt',
        [styles.nav__keto]: appearance === 'keto',
        [styles.nav__healthCase]: appearance === 'healthCase',
        [styles.nav__nft]: appearance === 'nft',
      })}
      {...props}
    >
      <ul className={styles.nav_list}>
        {menu &&
          menu.map(({ drop, href = '/', label, id }) => {
            if (drop) {
              return (
                <li key={id} className={cn(styles.drop, styles.nav_item)}>
                  {href ? (
                    <HoverLine appearance={appearance}>
                      <Link href={href} passHref prefetch={false}>
                        <a onClick={onHandleClick} aria-hidden="true">
                          {label}
                        </a>
                      </Link>
                    </HoverLine>
                  ) : (
                    label
                  )}

                  <Arrow className={styles.arrow} />
                  <ul className={styles.drop_list}>
                    {drop.map((dropItem) => (
                      <li key={dropItem.id} className={styles.drop_item}>
                        <HoverLine appearance="white">
                          <Link prefetch={false} href={dropItem.href} passHref>
                            <a onClick={onHandleClick} aria-hidden="true">
                              {dropItem.label}
                            </a>
                          </Link>
                        </HoverLine>
                      </li>
                    ))}
                  </ul>
                </li>
              );
            }
            return (
              <li key={id} className={styles.nav_item}>
                <HoverLine appearance={appearance}>
                  <Link href={href} passHref prefetch={false}>
                    <a onClick={onHandleClick} aria-hidden="true">
                      {label}
                    </a>
                  </Link>
                </HoverLine>
              </li>
            );
          })}
      </ul>
    </nav>
  );
}
