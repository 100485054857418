import { Container } from '../../components';
import styles from './domains.module.css';

export interface DomainsProps {
  domains: {
    icon: string;
    label: string;
  }[];
}

export default function Domains({ domains }: DomainsProps) {
  return (
    <section className={styles.domains}>
      <Container>
        <div className={styles.domains_inner}>
          <h2 className={styles.domains_title}>
            WE ARE CENTRED ON THESE DOMAIN
          </h2>
          <ul className={styles.domains_list}>
            {domains.map(({ icon, label }, index) => (
              <li className={styles.domains_item} key={index}>
                <div className={styles.domains_icon}>
                  <img src={icon} alt="icon" />
                </div>
                <div
                  className={styles.domains_label}
                  dangerouslySetInnerHTML={{ __html: label }}
                />
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </section>
  );
}
