import Link from 'next/link';
import React from 'react';
import styles from './mobile.module.css';
import Notification from './notification';
import Awards, { AwardsProps } from '../pages/templates/awards';
import Domains, { DomainsProps } from '../pages/templates/domains';
import Platform from '../pages/templates/platform';
import MobileDevelopment from '../pages/templates/mobile-development';
import { IArticles } from '../pages/blog/blog.types';
import WorksCard, { WorksCardProps } from './works-card';
import { Button, Container, PostPreviewList, WorksCardList } from '.';

export interface MobileProps {
  awards: AwardsProps;
  works: WorksCardProps[];
  domains: DomainsProps;
  posts: IArticles;
}

export default function Mobile({
  awards,
  works,
  posts,
  domains: { domains },
}: MobileProps) {
  return (
    <div className={styles.page}>
      <section className={styles.intro}>
        <Container>
          <div className={styles.intro_inner}>
            <div className={styles.intro_start}>
              <h1 className={styles.intro_title}>
                PRODUCTS AND SERVICES FOR MOBILE DEVELOPMENT
              </h1>
              <p className={styles.intro_text}>
                Our team uses programming languages that are specific to each
                platform, like Swift, Objective-C, Java, and Kotlin, to make
                native apps for Android and iOS.
              </p>
              <div className={styles.intro_button}>
                <Link href="/contact" passHref prefetch={false}>
                  <Button
                    className={styles.intro_button}
                    appearance="primary"
                    href=""
                  >
                    COMPUTE RIGHT NOW
                  </Button>
                </Link>
              </div>
            </div>
            {/* <div className={styles.intro_end}>
              <div>
                <img src="/mobile/android.svg" alt="" />
              </div>
              <div>
                <img src="/mobile/iOS.svg" alt="" />
              </div>
            </div> */}
          </div>
          <div className={styles.devided} />
        </Container>
      </section>
      <MobileDevelopment />
      <Platform />

      <Domains domains={domains} />
      <Container>
        <Notification
          title="HOW MUCH WILL YOUR APP SET ME BACK?"
          buttonName="COMPUTE RIGHT NOW"
          href="/contact"
          className={styles.notification}
        />
      </Container>

      <Awards {...awards} />

      <div className={styles.works}>
        <h2 className={styles.works_title}>RELATED CREATIONS</h2>
        <Container>
          <WorksCardList className={styles.works_list}>
            {works.map((work, index) => (
              <WorksCard key={index} {...work} />
            ))}
          </WorksCardList>
          <div className={styles.works_btn}>
            <Button appearance="primary" href="/experience">
              See all projects
            </Button>
          </div>
        </Container>
      </div>

      <section className={styles.posts}>
        <Container>
          <PostPreviewList
            title="Strategies for creating mobile applications"
            posts={posts}
          />
          <div className={styles.works_all}>
            <Button href="/blog?search=mobile" appearance="primary">
              READ ARTICLES
            </Button>
          </div>
        </Container>
      </section>
      <Container>
        <Notification
          title="HAVE AN IDEA FOR A PROJECT?"
          buttonName="SPEAK TO US"
          href="/contact"
          className={styles.notification}
        />
      </Container>
    </div>
  );
}
