import avatar_cruisbe from '../public/img/avatar_cruisbe.jpg';
import partner from '../public/img/partner.png';
import avatar_mobz from '../public/img/avatar_mobz.jpg';
import bestflix from '../public/img/bestflix.jpg';
import mel from '../public/img/mel.png';

const staticComments = [
  {
    _id: 1,
    img: mel,
    alt: 'M. Seers ',
    text: "The team has been putting in a lot of effort to bring the client's idea to fruition in its entirety. Even if there are language obstacles, websoftware.biz is able to communicate effectively with Slack, WhatsApp, and Zoom. In addition, the virtues that shine brightest in this working relationship are honesty and patience.",
    name: 'M. Seers ',
    company: 'Co-owner of the Hera mobile application',
  },
  {
    _id: 2,
    img: avatar_cruisbe,
    alt: 'avatar user',
    text: "They want to make the customer happy as much as possible... Since I'm a pretty picky IT engineer, it took them a while to figure out what I wanted, but we did it. And they will work on the feedback and make changes to the app until the customer is happy.",
    name: 'M. Shumaieva',
    company: 'Member of the founding team for CruiseBe',
  },
  {
    _id: 3,
    img: partner,
    alt: 'avatar user',
    text: "It was just right. Communication and making plans went quickly and on time. We set up calls by calling ahead, and we always did everything on time or talked about changes before they happened. They know what to do. I learned a lot from what they told me. They didn't go over their budget.",
    name: 'I. Nasinyk',
    company: 'BalloonPoster project co-founder',
  },
  {
    _id: 4,
    img: avatar_mobz,
    alt: 'avatar user',
    text: 'websoftware.biz successful app launch led in the signing of additional publisher agreements. It attracted over 50,000 users and an astounding amount of material. The engagement was seamless and hassle-free, and the crew was punctual and communicative.',
    name: 'A. Eisbrecher',
    company: 'CEO and Co-Founder at Mobiz',
  },
  {
    _id: 5,
    img: bestflix,
    alt: 'avatar user',
    text: "Since 2014, I've been working with Ruslan and his team. During this time, we collaborated on various mobile projects. I want to maintain our partnership with a dependable and responsive team of specialists for many years to come.",
    name: 'I. Pogoraev',
    company: 'CEO and Co-Founder of Bestflix',
  },
];

export default staticComments;
