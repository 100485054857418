import { AppProps } from 'next/app';
import '../styles/globals.css';
import { ModalProvider } from '../context';
import { Layout } from '../components';
import GoogleAnalytics from '../components/google-analytics';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ModalProvider>
      <Layout>
        <Component {...pageProps} />
        {process.env.NODE_ENV === 'production' && <GoogleAnalytics />}
      </Layout>
    </ModalProvider>
  );
}

export default MyApp;
