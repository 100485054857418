import { DetailedHTMLProps, HTMLAttributes } from 'react';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import styles from './post-preview-list.module.css';
import { IArticles } from '../pages/blog/blog.types';
import { Htag, HtagVariant } from '.';

export interface PostPreviewListProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  posts: IArticles;
  title?: string;
}

const DynamicPostPreview = dynamic(() => import('./post-preview'), {
  ssr: true,
});

export default function PostPreviewList({
  posts,
  className,
  title = 'websoftware.biz  THOUGHTS',
}: PostPreviewListProps) {
  return (
    <div className={cn(styles.insights, className)}>
      <Htag tag="h2" className={styles.title} variant={HtagVariant.h2}>
        {title}
      </Htag>
      <ul className={styles.list}>
        {posts.data.map(
          (post, index) =>
            index <= 2 && <DynamicPostPreview key={post.id} {...post} />,
        )}
      </ul>
    </div>
  );
}
