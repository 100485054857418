import Link from 'next/link';
import { Container } from '../../components';
import styles from './mobile-development.module.css';

export default function MobileDevelopment() {
  return (
    <section className={styles.development}>
      <Container>
        <h2 className={styles.development_title}>
          COMPLETE APPLICATION DEVELOPMENT FOR BOTH IOS AND ANDROID
        </h2>
        <p className={styles.development_text}>
          We&apos;d be pleased to tell you more about our offerings if
          you&apos;ve been thinking about making a mobile app for a while, or if
          the idea of app development is just fascinating to you. We develop
          apps from scratch for two different platforms and are happy to provide
          references. We&apos;ll fill you in on the rest.
        </p>
        <ul className={styles.development_list}>
          <li className={styles.development_item}>
            <div className={styles.development_itemImg}>
              <Link href="/ios" passHref prefetch={false}>
                <a>
                  <img src="/mobile-development/iOS.jpg" alt="" />
                </a>
              </Link>
            </div>
            <div className={styles.development_footer}>
              <Link href="/ios" passHref prefetch={false}>
                <a className={styles.development_itemName}>iOS</a>
              </Link>
              <div className={styles.development_itemContent}>
                Swift
                <br />
                Objective-C
              </div>
            </div>
          </li>

          <li className={styles.development_item}>
            <div className={styles.development_itemImg}>
              <Link href="/android" passHref prefetch={false}>
                <a>
                  <img src="/mobile-development/android.jpg" alt="" />
                </a>
              </Link>
            </div>
            <div className={styles.development_footer}>
              <Link href="/android" passHref prefetch={false}>
                <a>
                  <div className={styles.development_itemName}>Android</div>
                </a>
              </Link>

              <div className={styles.development_itemContent}>
                Java
                <br />
                Kotlin
              </div>
            </div>
          </li>
        </ul>
      </Container>
    </section>
  );
}
