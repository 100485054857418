import Image from 'next/image';
import Link from 'next/link';
import { IArticle } from '../pages/blog/blog.types';
import { rgbDataURL } from '../utils/rgbDataUrl';
import styles from './post-preview.module.css';

export default function PostPreview({ slug, cover, title }: IArticle) {
  return (
    <li className={styles.wrapper}>
      <a href={`/blog/${slug}`} className={styles.image}>
        <Image
          width={740}
          height={415}
          placeholder="blur"
          blurDataURL={rgbDataURL()}
          // priority
          src={cover}
          alt={title}
          // sizes="(max-width: 768px) calc(100% - 30px),
          //     (max-width: 1200px) calc(50% - 30px),
          //     calc(33.3333% - 30px)"
        />
      </a>
      <div className={styles.content}>
        <Link
          href={`/blog/${slug}`}
          className={styles.link}
          passHref
          prefetch={false}
        >
          <a>{title}</a>
        </Link>
      </div>
    </li>
  );
}
