import React from 'react';
import cn from 'classnames';
import styles from './post-sidebar.module.css';

export interface IPostSidebar {
  list: any[];
  active: number;
}

export default function PostSidebar({ list, active }: IPostSidebar) {
  return (
    <aside className={styles.sidebar}>
      <h6 className={styles.title}>Contents</h6>
      <ul className={styles.sidebar__list}>
        {list.map((item, index) => (
          <li key={item.id} className={styles.item}>
            <span
              className={cn(styles.count, {
                [styles.active]: active === index + 1,
              })}
            />
            <a
              className={cn(styles.link, {
                [styles.active]: active === index + 1,
              })}
              href={`#section${item.id}`}
            >
              {item.subtitle}
            </a>
          </li>
        ))}
      </ul>
    </aside>
  );
}
