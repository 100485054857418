import { forwardRef, ForwardedRef } from 'react';
import cn from 'classnames';
import styles from './text-field.module.css';
import { Error } from '.';

export interface ITextField extends React.HTMLAttributes<HTMLInputElement> {
  error?: boolean;
  message?: string;
  label?: string;
  disabled?: boolean;
  name?: string;
  type?: 'text' | 'password' | 'email' | 'time';
  maxLength?: number;
}

function TextField(
  {
    label,
    error,
    message,
    className,
    disabled,
    maxLength,
    ...props
  }: ITextField,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <div className={cn(styles.wrapper, className)}>
      {label && <label className={styles.label}>{label}</label>}
      <input
        className={cn(styles.input, {
          [styles.error]: !!error,
        })}
        disabled={disabled}
        ref={ref}
        maxLength={maxLength}
        {...props}
      />
      {error && <Error>{message}</Error>}
    </div>
  );
}

export default forwardRef(TextField);
