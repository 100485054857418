import { Container } from '../../components';
import styles from './platform.module.css';

export default function Platform() {
  return (
    <div className={styles.platform}>
      <Container>
        <div className={styles.platform_title}>FEATURES</div>
        <div className={styles.platform_inner}>
          <ul className={styles.platform_list}>
            <li className={styles.platform_item}>
              <ul className={styles.platform_secondList}>
                <li className={styles.platform_secondListItem}>Cineplex</li>
                <li className={styles.platform_secondListItem}>Gizmo</li>
                <li className={styles.platform_secondListItem}>
                  Speaker system
                </li>
              </ul>
            </li>
            <li className={styles.platform_item}>
              <ul className={styles.platform_secondList}>
                <li className={styles.platform_secondListItem}>
                  Recognition of fingerprints
                </li>
                <li className={styles.platform_secondListItem}>
                  Software Interfaces for Cameras
                </li>
                <li className={styles.platform_secondListItem}>Geoposition</li>
              </ul>
            </li>
            <li className={styles.platform_item}>
              <ul className={styles.platform_secondList}>
                <li className={styles.platform_secondListItem}>Ad campaigns</li>
                <li className={styles.platform_secondListItem}>
                  Payments made within the app
                </li>
                <li className={styles.platform_secondListItem}>Messaging</li>
                <li className={styles.platform_secondListItem}>
                  Sensors that detect movement
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </Container>
    </div>
  );
}
