import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import styles from './button.module.css';

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  appearance:
    | 'primary'
    | 'ghost'
    | 'letslink'
    | 'fitkonnekt'
    | 'keto'
    | 'healthCase'
    | 'design'
    | 'nft';
  href?: string;
  children: ReactNode;
}

export default function Button({
  appearance,
  children,
  className,
  href,
  ...props
}: ButtonProps) {
  return (
    <span
      className={cn(styles.container, {
        [styles.primary]: appearance === 'primary',
        [styles.ghost]: appearance === 'ghost',
        [styles.letslink]: appearance === 'letslink',
        [styles.fitkonnekt]: appearance === 'fitkonnekt',
        [styles.healthCase]: appearance === 'healthCase',
        [styles.keto]: appearance === 'keto',
        [styles.design]: appearance === 'design',
        [styles.nft]: appearance === 'nft',
      })}
    >
      {href ? (
        <Link href={href} prefetch={false}>
          <a>
            <button
              className={cn(styles.button, className)}
              type="button"
              {...props}
            >
              {children}
            </button>
          </a>
        </Link>
      ) : (
        <button
          className={cn(styles.button, className)}
          type="button"
          {...props}
        >
          {children}
        </button>
      )}
    </span>
  );
}
