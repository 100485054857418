import { DetailedHTMLProps, HTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './notification.module.css';
import { Button, Htag, HtagVariant } from '.';

export interface NotificationProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  href: string;
  title: string;
  buttonName: string;
}

export default function Notification({
  href,
  title,
  buttonName,
  className,
}: NotificationProps) {
  return (
    <div className={cn(styles.notification, className)}>
      <Htag tag="h3" className={styles.title} variant={HtagVariant.h5}>
        {title}
      </Htag>
      <Button appearance="primary" href={href}>
        {buttonName}
      </Button>
    </div>
  );
}
